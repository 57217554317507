body 
{
      background: #000000; /*337 x 5*/
      color: #cccccc;
      font-family: "Source Code Pro", monospace;
      font-size: 14pt;
}

p, td
{

    color: #008200;

}

a 
{ 
    outline:0; 
    text-decoration: none;
    color: #00aa00;
}

a:hover 
{

    color: #99ff99;

}

a:hover:not(.item-link)
{
    text-shadow: 0 0 5px red, 0 0 2px red, 0 0 8px red;
}

header
{
    margin: auto;
    position: relative;
    text-align: center;
    font-weight: normal;
}

header
{
    top: 2.5px;
    width: fit-content;
    outline: 1px solid green;
    box-shadow: 0px 0px 5px 2px blue;
}

ul 
{
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.title
{

    color: #00cc00;

}

/* latin-ext */
@font-face
{
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Code Pro Light'), local('SourceCodePro-Light'), url(https://fonts.gstatic.com/s/sourcecodepro/v8/HI_XiYsKILxRpg3hIP6sJ7fM7PqtlsnDvecq_mk.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Code Pro Light'), local('SourceCodePro-Light'), url(https://fonts.gstatic.com/s/sourcecodepro/v8/HI_XiYsKILxRpg3hIP6sJ7fM7PqtlsnDs-cq.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body {

    overflow: hidden;

}

#banner {
    text-shadow: rgb(0 204 0) 0px 0px 1px, rgb(0 204 0) 0px 0px 2px, rgb(0 204 0) 0px 0px 5px, rgb(0 204 0) 0px 0px 10px, rgb(0 204 0) 0px 0px 15px;
    color: #33FF33;
}

#banner:hover {
    color: #99FF99;
    text-shadow:
        rgb(0 204 0) 0px 0px 1px,
        rgb(0 204 0) 0px 0px 2px,
        rgb(0 204 0) 0px 0px 5px,
        0 0 5px red,
        0 0 2px red,
        0 0 8px red,
        0 0 10px red,
        0 0 12px red,
        0 0 15px red,
        0 0 18px red,
        0 0 20px red;
}